import dayjs from 'dayjs';

export type TDay = 'sunday' | 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday';

export function getYearAndMonth(date: string | null): string {
  if (!date) return '';
  return dayjs(date).format('YYYY.MM');
}

export function getManuDateRange(from: string, to: string) {
  return `${getYearAndMonth(from)} - ${getYearAndMonth(to)}`;
}

export function getStringDay(day: number): any {
  switch (day) {
    case 1: return 'Poniedziałek';
    case 2: return 'Wtorek';
    case 3: return 'Środa';
    case 4: return 'Czwartek';
    case 5: return 'Piątek';
    case 6: return 'Sobota';
    case 7: return 'Niedziela';
  }
}

export function getStringDayShortcut(day: number): any {
  switch (day) {
    case 0: return 'Niedz.';
    case 1: return 'Pon.';
    case 2: return 'Wt.';
    case 3: return 'Śr.';
    case 4: return 'Czw.';
    case 5: return 'Pt.';
    case 6: return 'Sob.';
  }
}

export function isToday(date: string) {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate());

  if (tomorrow.toDateString() === new Date(date).toDateString()) {
    return true;
  }

  return false;
}

export function isTomorrow(date: string) {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  if (tomorrow.toDateString() === new Date(date).toDateString()) {
    return true;
  }

  return false;
}

export function convertDayNumberToNameSpec(date: string): string {
  if (isToday(date)) return 'Dziś';
  if (isTomorrow(date)) return 'Jutro';
  return getStringDayShortcut(new Date(date).getDay());
}

export const dayNumberToString = (day: number): TDay => {
  switch (day) {
    case 0: return 'sunday';
    case 1: return 'monday';
    case 2: return 'tuesday';
    case 3: return 'wednesday';
    case 4: return 'thursday';
    case 5: return 'friday';
    case 6:
    default: return 'saturday';
  }
};

export const dateFormat = (date?: string | number) => {
  if (!date) return '-';
  return dayjs(date).format('DD.MM.YYYY');
};
